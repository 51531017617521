import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"

import siteLogo from "../../images/billsby-logo.svg"
import SEO from "../../components/seo"

const Success = () => {
  const [orderDetails, setOrderDetails] = useState({})
  const [creditAmount, setCreditAmount] = useState("$300,000")

  const handleClick = () => navigate("https://app.billsby.com/registration")

  const fetchPaymentDetails = async () => {
    const query = new URLSearchParams(window.location.search)
    const session_id = query.get("session_id")
    if (session_id) {
      axios
        .get(
          "https://billsby-mailgun.onrender.com/api/stripe/payment/success?session_id=" +
            session_id
        )
        .then(({ data }) => {
          setOrderDetails(data)
          if (data.amount === 9900) setCreditAmount("$100,000")
          if (data.amount === 19800) setCreditAmount("$200,000")
          if (data.amount === 29700) setCreditAmount("$300,000")
          if (data.amount === 39600) setCreditAmount("$400,000")
          if (data.amount === 49500) setCreditAmount("$500,000")
        })
        .catch(err => {
          console.log(err.response.data)
        })
    }
  }

  useEffect(() => {
    fetchPaymentDetails()
  }, [])

  return (
    <div className="lifetime-checkout">
      <SEO
        title="0.4% of revenue share | Billsby"
        description="The most competitively priced subscription billing software around can process recurring payments for your business."
        url="https://www.billsby.com/lifetime-checkout/success"
      />
      <div className="header-checkout">
        <div className="container">
          <div className="header-wrapper">
            <Link to="/">
              <img className="logo-orig" src={siteLogo} alt="Logo" />
            </Link>
            <div className="secure-checkout">
              <FontAwesomeIcon icon={faLock} />
              <p>Secure Checkout</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-checkout-box">
        <div className="container">
          <div className="checkout-box-wrapper">
            <h2>Thank you!</h2>
            <p>
              You’re making {creditAmount} of annual revenue with no transaction
              fees. If you haven’t already, please{" "}
              <span className="text-bold">
                create a Billsby account and ‘Go Live’
              </span>
              , then reach out to a member of our service team with order number{" "}
              <span className="text-bold">{orderDetails?.id}</span> to have your{" "}
              {creditAmount} per year credit applied.
            </p>
            <p>
              If you’ve already got an account, please go live if you haven’t
              already, then reach out to a member of our service team with order
              number <span className="text-bold">{orderDetails?.id}</span> to
              have your {creditAmount} per year credit applied.
            </p>
            <button
              type="button"
              className="btn-orange btn-full-width"
              role="link"
              onClick={handleClick}
            >
              Create a new billsby acount
            </button>
          </div>
        </div>
      </div>
      <div className="footer-checkout">
        <div className="container">
          <p>© Billsby 2021</p>
          <span>Powered by Airfi Networks</span>
        </div>
      </div>
    </div>
  )
}

export default Success
